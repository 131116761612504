<template>
  <div class="px-16 py-4 w-full">
    <!-- Ziaraat -->
    <p class="text-gray-800 text-2xl w-full text-center pb-10">Ziaraat</p>
    <div class="flex flex-wrap mb-4">
      <div
        v-for="(img, key) in galleryZiaraat"
        :key="key"
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 my-4 mx-4"
      >
        <a :href="require('../assets/ziaraat/' + img.file)" target="_blank"
          ><img
            alt="gallery"
            class="block object-cover object-center h-18 rounded-lg hover:scale-110 transition duration-300 ease-in-out"
            :src="require('../assets/ziaraat/' + img.file)"
        /></a>
        <p class="text-center text-sm">{{ img.name }}</p>
      </div>
    </div>

    <!-- Imambargah -->
    <hr />
    <p class="text-gray-800 text-2xl w-full text-center mt-8 pb-10">
      Imambargah
    </p>
    <div class="flex flex-wrap">
      <div
        v-for="(img, key) in galleryBargah"
        :key="key"
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 my-4 mx-4"
      >
        <a :href="require('../assets/' + img)" target="_blank"
          ><img
            alt="gallery"
            class="block object-cover object-center h-18 rounded-lg hover:scale-110 transition duration-300 ease-in-out"
            :src="require('../assets/' + img)"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Gallery",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    galleryZiaraat() {
      return this.$store.state.galleryZiaraat;
    },
    galleryBargah() {
      return this.$store.state.galleryBargah;
    },
  },
};
</script>
