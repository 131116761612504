<template>
  <div class="w-full flex">
    <!-- SideBar -->
    <div
      v-if="showSideNav"
      class="absolute relative bg-gray-800 border-t border-b border-gray-900"
    >
      <div class="px-2">
        <ul class="mt-8">
          <li
            v-for="(count, key) in sideNav"
            :key="key"
            @click="option = key"
            class="flex justify-between w-full hover:text-gray-200 cursor-pointer items-center mb-6"
            :class="key == option ? 'text-gray-200' : 'text-gray-500'"
          >
            <div class="items-center">
              <span class="text-xs">{{ key }}</span>
            </div>
            <div
              class="ml-1 py-1 px-1 bg-gray-700 rounded text-gray-500 text-xs"
            >
              {{ count }}
            </div>
          </li>
          <template v-if="hidden_majalis">
              <li
                @click="option = hidden_majalis_key"
                class="flex justify-between w-full hover:text-gray-200 cursor-pointer items-center mb-6"
                :class="hidden_majalis_key == option ? 'text-gray-200' : 'text-gray-500'"
              >
                <div class="items-center">
                  <span class="text-xs">{{hidden_majalis_key}}</span>
                </div>
                <div
                  class="ml-1 py-1 px-1 bg-gray-700 rounded text-gray-500 text-xs"
                >
                  {{ Object.keys(hidden_majalis).length }}
                </div>
              </li>
          </template>
        </ul>
        <svg v-if="!ourMajalis" @click="reveal()" class="bg-gray-800 text-center w-full my-2" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
        <path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"/>
      </svg>
      </div>
    </div>

    <div class="mt-2 h-full cursor-pointer" @click="showSideNav = !showSideNav">
      <svg
        class="block rounded-r-sm h-4 w-4 bg-gray-800 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </div>

    <!-- Imambargah -->
    <div class="flex flex-wrap justify-between mt-4 w-full overflow-x-auto">
      <div
        v-for="(link, key) in majalis"
        :key="key"
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mx-4 my-4"
      >
        <div class=""><p class="text-center text-sm pt-1">{{ key }}</p></div>
        <div class="relative overflow-hidden h-36">
          <iframe
            class="absolute top-0 left-0 w-full h-full"
            :src="link"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <template v-if="hidden_majalis && option == hidden_majalis_key">
        <div
        v-for="(link, key) in hidden_majalis"
        :key="key"
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mx-4 my-4"
      >
        <div class=""><p class="text-center text-sm pt-1">{{ key }}</p></div>
        <div class="relative overflow-hidden h-36">
          <iframe
            class="absolute top-0 left-0 w-full h-full"
            :src="link"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Majalis",
  props: {
    ourMajalis: Boolean,
  },
  data() {
    return {
      option: "",
      hidden_majalis: null,
      hidden_majalis_key: 'More Majalis',
      showSideNav: true,
    };
  },
  methods: {
    reveal() {
      let text = prompt("Enter password.")
      if(text == this.$store.state.try) {
        this.hidden_majalis = this.$store.state.secret_majalis;
        this.option = this.hidden_majalis_key;
      }
    }
  },
  mounted() {
    let obj = this.ourMajalis
      ? this.$store.state.our_majalis
      : this.$store.state.other_majalis;
    let k = Object.keys(obj);
    this.option = k[0];
  },
  computed: {
    majalis() {
      let obj = this.ourMajalis
        ? this.$store.state.our_majalis
        : this.$store.state.other_majalis;
      if (this.option.length > 0) {
        return obj[this.option];
      } else {
        let k = Object.keys(obj);
        return obj[k[0]];
      }
    },
    sideNav() {
      let ret = {};
      let obj = this.ourMajalis
        ? this.$store.state.our_majalis
        : this.$store.state.other_majalis;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          ret[key] = Object.keys(obj[key]).length;
        }
      }
      return ret;
    },
  },
};
</script>

<style scoped>
.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>